// eslint-disable-next-line simple-import-sort/imports
import React from 'react';
import ReactMarkdown from 'react-markdown';

import { ReactMarkdownOptions } from 'react-markdown/lib/react-markdown';
import style from 'common-ui-components/Markdown/style.module.scss';

interface Props extends ReactMarkdownOptions {}

export default function Markdown({
  ...props
}: Props) {
  return (
    <ReactMarkdown
      linkTarget="_blank"
      className={style.markdown}
      {...props}
    />
  );
}
