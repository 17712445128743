import { useAsync } from 'react-use';

import Api from 'global/api/platformApi';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import { useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';

export default function useSuggestedQuestions() {
  const {
    tenant: { id: tenantId },
  } = useTenantContext();
  const { tenantId: impersonatedTenantId } = useUserContext();
  const { value: suggestedQuestions, loading } = useAsync(async () => {
    if (impersonatedTenantId !== tenantId) {
      return [];
    }

    const response = await Api.EnterpriseSearch.getSuggestedQuestions(tenantId);
    return response?.data ?? [];
  }, [tenantId]);

  return {
    suggestedQuestions,
    loading,
  };
}
