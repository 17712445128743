import React from 'react';

import AppIcon from 'common-ui-components/AppIcon';
import SourceBadge from 'common-ui-components/Sources/SourceBadge/index';
import SalesforcePopoverContent from 'common-ui-components/Sources/SourcePopoverContent/SalesforcePopoverContent';
import Analytics, { AnalyticsEvent } from 'global/Analytics';
import { SalesforceSource } from 'global/api/controller/EnterpriseSearchController';
import App from 'global/lists/apps';
import Size from 'global/lists/Size';
import { useMountedState } from 'utils/hooks';

const DEFAULT_NAME = 'Record';

interface Props {
  source: SalesforceSource;
}

export default function SalesforceRecordBadge({ source }: Props) {
  const { type, data: salesforceRecord } = source;
  const { id, permalink, name } = salesforceRecord;
  const [selectedAtomId, setSelectedAtomId] = useMountedState<string | null>(null);

  const analyticsPayload = {
    sourceType: type,
    sourceId: permalink,
  };

  function handlePopoverHover() {
    Analytics.trackEvent(
      AnalyticsEvent.ES_SOURCE_TOOLTIP_USED,
      analyticsPayload,
    );
  }

  function handleSourceBadgeClick() {
    Analytics.trackEvent(
      AnalyticsEvent.ES_SOURCE_BADGE_CLICK,
      analyticsPayload,
    );
  }

  return (
    <SourceBadge
      type={type}
      redirect={permalink}
      title={name ?? DEFAULT_NAME}
      image={(
        <AppIcon
          appId={App.SALESFORCE}
          isPublic={false}
          hasBorder={false}
          size={Size.SMALL}
          mimeType={undefined}
        />
      )}
      popoverContent={<SalesforcePopoverContent salesforceRecord={salesforceRecord} />}
      handlePopoverHover={handlePopoverHover}
      handleSourceClick={handleSourceBadgeClick}
      isSelected={selectedAtomId === id}
      onSelect={(newValue) => setSelectedAtomId(newValue)}
      id={id}
      appId={App.SALESFORCE}
    />
  );
}
