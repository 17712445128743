import colors from 'global/colors';

export enum DEPARTMENT_CATEGORIES {
  LEADERSHIP = 'LEADERSHIP',
  SALES = 'SALES',
  MARKETING = 'MARKETING',
  CUSTOMER_SUCCESS = 'CUSTOMER_SUCCESS',
  RESEARCH_AND_DEVELOPMENT = 'RESEARCH_AND_DEVELOPMENT',
  IT = 'IT',
  PRODUCT = 'PRODUCT',
  HR = 'HR',
  ADMINISTRATION = 'ADMINISTRATION',
  OPERATIONS = 'OPERATIONS',
}
export interface Department {
  id: string;
  label: string;
  color: string;
  category: DEPARTMENT_CATEGORIES | null;
}

export enum SETUP_STATE {
  SETUP = 'SETUP',
  SCANNING = 'SCANNING',
  READY = 'READY',
}

export enum PersonPropertyType {
  DEFAULT = 'DEFAULT',
  TIME_IN_COMPANY = 'TIME_IN_COMPANY',
}

export interface PersonProperty {
  type: PersonPropertyType;
}

interface Tenant {
  id: number;
  name: string;
  domain: string;
  departments: Partial<Record<string, Department>>;
  setupState: SETUP_STATE;
  personProperties?: Record<string, PersonProperty>;
  isSubscriptionExpired?: boolean;
}

export const UNASSIGNED_DEPARTMENT = 'Unassigned';
export const EXTERNAL_ORGANIZATIONS_DEPARTMENT = 'External Organizations';
export const RECENTLY_DELETED_DEPARTMENT = 'Recently Deleted';

export const UNASSOCIATED_CATEGORY = 'UNASSOCIATED';
export const UNKNOWN_ORGANIZATION = 'Unknown';

export const ADDITIONAL_CATEGORIES_COLORS = {
  [UNASSOCIATED_CATEGORY]: colors.GRAY_4,
};
export const INITIAL_DEPARTMENTS_COLORS = {
  [UNASSIGNED_DEPARTMENT]: {
    label: UNASSIGNED_DEPARTMENT,
    color: 'black',
    id: '0',
    category: null,
  },
};

export default Tenant;
