import classNames from 'classnames';
import React, {
  ReactNode, useEffect, useRef, useState,
} from 'react';

import Button from 'common-ui-components/Button';
import CSSVariableDefiner from 'common-ui-components/CSSVariableDefiner';
import ExternalLink from 'common-ui-components/ExternalLink';
import Tooltip from 'common-ui-components/Tooltip';
import SummarizeTooltip
  from 'es-src/screens/HomeScreen/components/ResultSection/RegularSearchResults/AtomSearchResult/SummarizeTooltip';
import { Source } from 'global/api/controller/EnterpriseSearchController';
import Api from 'global/api/platformApi';
import App from 'global/lists/apps';
import useTenantContext from 'screens/platform/cross-platform-components/context/tenant/TenantContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import { useMountedState } from 'utils/hooks';

import { ReactComponent as ExternalLinkIcon } from 'assets/img/icon/external-link.svg';
import { ReactComponent as SparklesIcon } from 'assets/img/icon/sparkles-icon.svg';

import style from 'common-ui-components/Sources/SourceBadge/style.module.scss';

const TOOLTIP_MAX_WIDTH = '400px';
const CARD_MAX_WIDTH = 210;

interface CommonProps {
  type: Source['type'];
  image: ReactNode;
  title: string;
  popoverContent: ReactNode;
  handlePopoverHover: () => void;
  handleSourceClick: () => void;
}

interface InteractionBadgeProps extends CommonProps {
  type: 'atom' | 'salesforce';
  redirect: string;
  isSelected: boolean;
  onSelect: (newValue: (string | ((prev: (string | null)) => (string | null)) | null)) => void;
  id: string;
  appId: App;
}

interface EntityBadgeProps extends CommonProps {
  type: 'person' | 'organization';
  redirect: () => void;
}

type Props = InteractionBadgeProps | EntityBadgeProps;

function useDynamicSourceBadgeWidth() {
  const [width, setWidth] = useMountedState(CARD_MAX_WIDTH);
  const ref = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current !== null) {
      const dynamicWidth = ref.current.getBoundingClientRect().width;
      setWidth(Math.min(CARD_MAX_WIDTH, dynamicWidth));
    }
  }, [ref.current]);

  return {
    width, ref,
  };
}

export default function SourceBadge({
  type,
  image,
  title,
  popoverContent,
  redirect,
  handlePopoverHover,
  handleSourceClick,
  ...props
}: Props) {
  const { width, ref } = useDynamicSourceBadgeWidth();
  const { tenant: { id: tenantId } } = useTenantContext();
  const [isSummarizeTooltipOpen, setSummarizeTooltipOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [summaryText, setSummaryText] = useState<string | undefined>(undefined);
  const [sessionId, setSessionId] = useState<string | undefined>(undefined);
  const summarizeTooltipRef = useRef<HTMLDivElement | null>(null);

  const handleSummarize = async () => {
    if (type !== 'atom' && type !== 'salesforce') return;

    const { id, onSelect, appId } = props as InteractionBadgeProps;

    onSelect(id);
    setSummarizeTooltipOpen(true);

    if (summaryText) return;

    try {
      setIsLoading(true);
      const response = await Api.Atom.getAtomSummary(tenantId, id, appId);
      setSummaryText(response?.data?.answer);
      setSessionId(response?.data?.sessionId);
    } catch (error) {
      DebuggerConsole.error('Failed to fetch summary of source', error);
      setHasError(true);
      onSelect(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (type !== 'atom' && type !== 'salesforce') return;
    const { onSelect } = props as InteractionBadgeProps;

    setSummarizeTooltipOpen(false);
    onSelect(null);
  };

  const handleClickOutside = (event: Event) => {
    if (summarizeTooltipRef.current
      && !summarizeTooltipRef.current.contains(event.target as Node)) {
      handleClose();
    }
  };

  const onClick = type === 'person' || type === 'organization' ? redirect : undefined;
  const permalink = type === 'atom' || type === 'salesforce' ? redirect : undefined;

  return (
    <CSSVariableDefiner variables={{ '--source-badge-max-width': `${width}px` }}>
      <Tooltip
        animation={false}
        maxWidth={TOOLTIP_MAX_WIDTH}
        content={popoverContent}
        onShow={handlePopoverHover}
        placement="top"
        popperOptions={{ modifiers: [{ name: 'flip', enabled: false }] }}
      >
        <div
          className={classNames(
            style.sourceBadge,
            (props as InteractionBadgeProps).isSelected && style.selected,
          )}
          ref={ref}
        >
          <OptionalExternalLinkWrapper
            permalink={permalink}
            handleSourceClick={handleSourceClick}
            className={style.sourceBadgeButtonLinkWrapper}
          >
            <Button
              className={style.sourceBadgeButton}
              transparent
              onClick={onClick}
              aria-label="source-button"
            >
              {image}
              <span className={style.sourceTitle} data-testid="source-title">{title}</span>
              <ExternalLinkIcon className={style.externalLinkIcon} aria-label="external-link-icon" />
            </Button>
          </OptionalExternalLinkWrapper>

          {(type === 'atom' || type === 'salesforce') && (
            <>
              <div className={style.divider} />
              <Tooltip
                content={(
                  <SummarizeTooltip
                    isOpen={isSummarizeTooltipOpen}
                    onClose={handleClose}
                    tooltipRef={summarizeTooltipRef}
                    isLoading={isLoading}
                    hasError={hasError}
                    summaryText={summaryText}
                    sessionId={sessionId}
                  />
                )}
                className={style.summaryTooltip}
                visible={isSummarizeTooltipOpen}
                interactive
                arrow={false}
                onClickOutside={(_, event) => handleClickOutside(event)}
                placement="bottom"
                popperOptions={{ modifiers: [{ name: 'flip', enabled: false }] }}
              >
                <Button
                  onClick={handleSummarize}
                  className={style.summarizeButton}
                  transparent
                >
                  <SparklesIcon className={style.sparklesIcon} />
                </Button>
              </Tooltip>
            </>
          )}
        </div>
      </Tooltip>
    </CSSVariableDefiner>
  );
}

interface WrapperProps {
  permalink: string | undefined;
  handleSourceClick: () => void;
  children: ReactNode;
  className: string;
}

function OptionalExternalLinkWrapper({
  permalink, handleSourceClick, children, className,
}: WrapperProps) {
  return permalink
    ? (
      <ExternalLink to={permalink} onClick={handleSourceClick} className={className}>
        {children}
      </ExternalLink>
    )
    : (
      <>
        { children }
      </>
    );
}
