import { EnrichedPersonSource } from 'es-src/screens/HomeScreen/EnterpriseSearchContext/EnrichedSourcesDataHook';
import { Atom } from 'model/Atom';
import Person, { MinimalPerson } from 'model/Person';
import ArrayUtils from 'utils/ArrayUtils';

const MAX_RELEVANT_PEOPLE = 3;

interface PersonWithId {
  _id: string;
  person: Person | undefined;
}

interface IdByVolume {
  id: string;
  volume: number;
}

export interface PersonFetcher {
  getById(personId: string): Promise<Person | undefined>;
}

export default class RelevantPeopleAlgorithm {
  constructor(private readonly personFetcher: PersonFetcher) {
    this.personFetcher = personFetcher;
  }

  async toRelevantPeople(
    people: MinimalPerson[],
    sourcesPeople: EnrichedPersonSource[],
    interactions: Atom[],
  ): Promise<MinimalPerson[]> {
    const relevantPeople = [
      ...people,
      ...this.sourcesPeopleToMinimalPeople(sourcesPeople),
      ...await this.interactionsToInternalMinimalPeople(interactions),
    ];
    const uniqueIds = this.uniqueById(relevantPeople.map((person) => ({ id: person._id })));
    return uniqueIds
      .slice(0, MAX_RELEVANT_PEOPLE)
      .map((id) => relevantPeople.find((person) => person._id === id))
      .filter(ArrayUtils.isDefined);
  }

  private sourcesPeopleToMinimalPeople(sourcesPeople: EnrichedPersonSource[]): MinimalPerson[] {
    return sourcesPeople
      .map((sourcePerson) => sourcePerson.data)
      .filter(ArrayUtils.isDefined)
      .map((person) => this.personToMinimalPerson(person, person._id));
  }

  private async interactionsToInternalMinimalPeople(
    interactions: Atom[],
  ): Promise<MinimalPerson[]> {
    if (interactions.length === 0) {
      return [];
    }

    const peopleIds = this.uniqueById(
      interactions
        .flatMap((interaction) => this.peopleIdsFromInteractionSortedByVolume(interaction))
        .sort(this.sortByVolume),
    );
    const people: PersonWithId[] = [];
    for (const id of peopleIds) {
      people.push({
        _id: id,
        // We need to fetch the people sequentially
        // eslint-disable-next-line no-await-in-loop
        person: await this.personFetcher.getById(id),
      });
    }

    return people
      .filter(({ person }) => person && !person.isExternal)
      .map(({ person, _id }) => this.personToMinimalPerson(person!, _id));
  }

  private personToMinimalPerson(person: Person, personDocumentId: string): MinimalPerson {
    return {
      _id: personDocumentId,
      name: person.name,
      department: person.department,
    };
  }

  private peopleIdsFromInteractionSortedByVolume(interaction?: Atom): IdByVolume[] {
    if (!interaction) {
      return [];
    }

    return Object.entries(interaction.participants)
      .map(([id, participantVolume]) => ({ id, volume: participantVolume * interaction.volume }))
      .sort(this.sortByVolume);
  }

  private sortByVolume(a: IdByVolume, b: IdByVolume): number {
    return a.volume > b.volume ? -1 : 1;
  }

  private uniqueById(peopleIds: { id: string }[]): string[] {
    return [
      ...new Set(peopleIds.map((person) => person.id)),
    ];
  }
}
