import axios from 'axios';
import { useHistory } from 'react-router-dom';

import Authentication, { AvailableProviders } from 'global/authentication/Authentication';
import {
  isFirebaseIgnorableException,
  TenantSubscriptionExpiredException,
  UnauthorizedException,
  UserNotAllowedInSetupModeException,
} from 'global/authentication/AuthenticationException';
import { sendTokenToChromeExtension } from 'global/ChromeExtensionUtils';
import { AuthError, useUserContext } from 'screens/platform/cross-platform-components/context/user/UserContext';
import DebuggerConsole from 'utils/DebuggerConsole';
import useMobileWarning from 'utils/MobileUtils';

export default function useLoginWithAuthProvider() {
  const { setAuthError, startUserSession } = useUserContext();
  const { isMobile, showMobileWarning } = useMobileWarning();
  const history = useHistory();

  return async (authProvider: AvailableProviders, tenantId?: string) => {
    if (isMobile) {
      showMobileWarning();
      return;
    }

    try {
      const signInResult = await Authentication.openSignInPopup(authProvider, tenantId);
      const token = await signInResult?.user?.getIdToken();
      if (token) {
        const userState = await Authentication.authorizeUserByToken(token);
        sendTokenToChromeExtension(token);
        startUserSession(userState, authProvider);
        history.push('/');
      }
    } catch (err) {
      if (!isFirebaseIgnorableException(err)) {
        DebuggerConsole.error(`An error occurred during login with ${authProvider} credentials`, err);
      }
      if (err instanceof TenantSubscriptionExpiredException) {
        setAuthError(AuthError.TENANT_SUBSCRIPTION_EXPIRED);
      }
      if (err instanceof UserNotAllowedInSetupModeException) {
        setAuthError(AuthError.NOT_ALLOWED_IN_SETUP_MODE);
      }
      if (err instanceof UnauthorizedException) {
        setAuthError(AuthError.UNAUTHORIZED);
      }
      if (axios.isAxiosError(err)) {
        setAuthError((err.message as AuthError));
      }
    }
  };
}
